.App {
  text-align: center;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  font-weight: bold;
}


.FlexWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.MailtoContainer {
  font-size: calc(20px + 2vmin);
  font-weight: bold;
  padding: 3vmin 0 3vmin 0;
}

.StubContainer {
  width: 60%;
  height: 60%;
}
